<template>
  <div>
    <div class="header">
      <h4>发票功能</h4>
    </div>
    <el-row :gutter="12" style="margin-top: 32px">
      <el-col :span="8" v-access data-ctrl="invoice.enterSubCard">
        <el-card shadow="always" v-prev path="/system/invoice/invoice-list">
          <el-button size="medium" type="text">发票管理</el-button>
        </el-card>
      </el-col>
      <el-col :span="8" v-access data-ctrl="invoiceApply.enterCard">
        <el-card
          shadow="always"
          v-prev
          path="/system/invoice/enterprise-invoice-list"
        >
          <el-button size="medium" type="text">发票申请管理</el-button>
        </el-card>
      </el-col>
      <el-col :span="8" v-access data-ctrl="invoiceInfo.enterCard">
        <el-card shadow="always" v-prev path="/system/invoice/invoiceInfo-List">
          <el-button size="medium" type="text">发票信息管理</el-button>
        </el-card>
      </el-col>
      <!-- <el-col :span="8" v-access data-ctrl="invoice.enterCard">
        <el-card
          shadow="always"
          v-prev
          path="/system/invoice/platform-invoice-list"
        >
          <el-button size="medium" type="text">平台发票列表</el-button>
        </el-card>
      </el-col>-->
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: 1,
    };
  },
  methods: {
    getUserStastic() {
      this.index++;
    },
  },
};
</script>

<style></style>
